import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import SVG from 'react-inlinesvg';

// Components
import { Link } from 'react-router-dom';
import Notifications from '../shared/Notifications';

import '../../../stylesheets/shared/components/Tippy.postcss';
import styles from './Header.module.postcss';

class HeaderNavControls extends PureComponent {
  render() {
    const { auth, admin, onLogout, cityConfig } = this.props;

    let cateringUser = false;
    let vendorUser = false;
    if (auth.userData) {
      cateringUser = auth.userData.catering_user;
      vendorUser = !cateringUser && !auth.userData.admin;
    }

    let addTruckLink = "/vendors";
    if (cityConfig.city_name === "Portland") {
      addTruckLink = "/vendor/register"
    }

    let dashboardLink = "/welcome";
    if (cateringUser) {
      dashboardLink = "/catering/dashboard";
    } else if (vendorUser) {
      dashboardLink = "/vendor";
    }

    return (
      <React.Fragment>

        {/*
          * Mobile Links
          */}

        {!auth.loggedIn &&
          <Link
            className={styles.GlobalHeader_navLink + " u-mobileOnly"}
            to={addTruckLink}
          >
            Add Your {cityConfig.vehicle_type}
          </Link>
        }

        {auth.loggedIn && auth.admin &&
          <Link
            to="/admin"
            className={styles.GlobalHeader_navLink + " u-mobileOnly"}
          >
            Admin
          </Link>
        }

        {auth.loggedIn && !auth.admin &&
          <div className={styles.GlobalHeader_navButton}>
            <Link
              to={dashboardLink}
              className={styles.GlobalHeader_navLink + " u-mobileOnly"}
            >
              Dashboard
            </Link>
          </div>
        }

        {!cateringUser &&
          <Link
            className={styles.GlobalHeader_navLink + " u-mobileOnly"}
            to="/support"
          >
            Support
          </Link>
        }

        {auth.loggedIn && cateringUser &&
          <React.Fragment>
            <Link
              to="/account"
              className={styles.GlobalHeader_navLink + " u-mobileOnly"}
            >
              Account Settings
            </Link>
            <Link
              to="/catering/contact"
              className={styles.GlobalHeader_navLink + " u-mobileOnly"}
            >
              Support
            </Link>
          </React.Fragment>
        }

        {auth.loggedIn &&
          <button
            onClick={onLogout}
            className={styles.GlobalHeader_navLink + " u-mobileOnly"}
          >
            Log Out
          </button>
        }


        {/*
          * Desktop Links
          */}

        <div className={styles.GlobalHeader_navControls + " u-noMobileInlineFlex"}>
          {!auth.loggedIn &&
            <div className={styles.GlobalHeader_navButton}>
              <Link
                className="Button Button--outlined Button--small u"
                to="/login"
              >
                Log In
              </Link>
            </div>
          }

          {!auth.loggedIn &&
            <div className={styles.GlobalHeader_navButton}>
              <Link
                className="Button Button--small"
                to={addTruckLink}
              >
                Add Your {cityConfig.vehicle_type}
              </Link>
            </div>
          }

          {auth.loggedIn && auth.admin &&
            <div className={styles.GlobalHeader_navButton}>
              <Link
                to="/admin"
                className="Button Button--outlined Button--small"
              >
                Admin
              </Link>
            </div>
          }

          {auth.loggedIn && !auth.admin &&
            <div className={styles.GlobalHeader_navButton}>
              <Link
                to={dashboardLink}
                className="Button Button--outlined Button--small"
              >
                Dashboard
              </Link>
            </div>
          }

          {auth.loggedIn && !cateringUser &&
            <div className={styles.GlobalHeader_navButton}>
              <button
                onClick={onLogout}
                className="Button Button--outlined Button--small"
              >
                Log Out
              </button>
            </div>
          }

          {auth.loggedIn && cateringUser &&
            <Tippy
              placement='bottom'
              trigger='click'
              theme='light'
              appendTo={() => document.body}
              interactive
              content={(
                <div className="AccountTooltip-inner">
                  <a href='/catering/contact'>
                    Support
                  </a>
                  <a href="#" onClick={onLogout}>
                    Sign Out
                  </a>
                </div>
              )}
            >
              <div className={styles.AccountIcon + " u-mr1"}>
                <SVG src='/static/images/icons/user.svg'/>
              </div>
            </Tippy>
          }

          {auth.loggedIn &&
            <Notifications
              admin={admin}
              noFetch
            />
          }
        </div>
      </React.Fragment>
    );
  }
}

HeaderNavControls.propTypes = {
  auth: PropTypes.object,
  admin: PropTypes.bool,
  onLogout: PropTypes.func,
};

export default HeaderNavControls;
